import React from 'react';

import './menu.css';


export class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_visible: false,
        };
        this.handClick = this.handClick.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }
    handClick(e) {
        this.toggleMenu();
        e.stopPropagation();
    }
    toggleMenu() {
        this.setState(
            {
                is_visible: !this.state.is_visible
            }
        );
        console.log(this.visibility())
    }
    visibility(){
        return(
            this.state.is_visible ? "show": "hide"
        );
    }
    render () {
        return (
            <div className={"menu " + this.visibility()}
                 id="footer"
                 style={{backgroundColor: `${this.props.menuColor}`}}
            >
                <div className="d-flex justify-content-center">
                    <button className="button-toggle-menu"
                            onMouseDown={this.handClick}>
                        <div className="h1 slide-button"><i className="bi bi-chevron-double-up"></i></div>
                    </button>                        
                </div>
                <div className="content d-flex flex-row justify-content-around">
                    {this.props.children}
                </div>
            </div>
        )
    }
}