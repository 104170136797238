import React from "react";

import {BlankButton} from './buttons';

const icons = {
        "mail": <i className="bi bi-envelope"></i>,
        "whatsapp": <i className="bi bi-whatsapp"></i>,
        "phone": <i className="bi bi-telephone"></i>,
        "linkedin": <i className="bi bi-linkedin"></i>,
        "instagram": <i className="bi bi-instagram"></i>,
        "facebook": <i className="bi bi-facebook"></i>,
        "twitter": <i className="bi bi-twitter"></i>,
        "webpage": <i className="bi bi-globe2"></i>,
        "github": <i className="bi bi-github"></i> 
}

const contactLinks = {
    "mail": "mailto:joaoclaudio82@gmail.com",
    "whatsapp": "https://wa.me/message/",
    "phone": "tel:+5585996597099",
    "linkedin": "https://www.linkedin.com/in/joaoclaudionc/",
    "webpage": "http://www.joaoclaudio.com.br/",
    "instagram": "https://www.instagram.com/joaoclaudioncarvalho/",
    "facebook": "https://www.facebook.com/joaoclaudioncarvalho/",
    "twitter": "https://twitter.com/JoaoClaudioNun3",
    "github": "https://github.com/bessavagner"
};

export class SocialMenu extends React.Component{
    render() {
        const {
            buttonColor,
            width,
            height,
            buttonSize
        } = this.props
        return (
            <div className="social-menu d-flex flex-row flex-wrap justify-content-around"
                 style={{width: `${width}`, height: `${height}`}}>
                {
                    Object.keys(contactLinks).map((icon, index) => {
                        return (
                            <BlankButton
                                key={icon + '-' + index}
                                href={contactLinks[icon]}
                                buttonColor={buttonColor}
                                fontSize={buttonSize}
                            >
                                {icons[icon]}
                            </BlankButton>
                        )
                    })
                }
            </div>
        );
    }
}