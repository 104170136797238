import {Wrapper} from './components/wrapper';


function App() {
  return (
    <div className="App">
      <Wrapper />
    </div>
  );
}

export default App;
