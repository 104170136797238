import React from "react";

import './header.css';


export class Header extends React.Component {
    render () {
        const {
            logoSrc,
            title,
            titleFont,
            description,
            descriptionFont,
            profilePictureSrc,
            overlayColor,
        } = this.props;
        return (
            <div className="header d-flex flex-column justify-content-start"
                 id="header-gradient">
                <img className="logo"
                     alt="initials"
                     src={logoSrc}
                />
                <div className="infos">
                    <div className="title h1"
                         style = {titleFont}>
                        {title}
                    </div>
                    <div className="description h2"
                         style = {descriptionFont}>
                        {description}
                    </div>
                </div>
                <img className="profile-picture rounded-circle"
                     src={profilePictureSrc}
                     style = {{border: `8px solid ${overlayColor}`}}
                     alt={`${title}`}
                />
                <div className="triangle"
                     style={{borderBottomColor: `${overlayColor}`}}
                >
                </div>
            </div>
        );
    }
}