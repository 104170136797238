export const palette = {
    spec: {
        blueSapphire: "#2d5362ff",
        keppel: "#2fb1a2ff",
        lightCoral: "#ef767aff",
        minionYellow: "#ffe347ff",
        eggShell: "#faf3ddff",
        babyPower: "#FFFEFBFF",
        cultured: "#F5F5F5FF"        
    },
    bluey: {
        smokyBlack: "#111110ff",
        eerieBlack: "#1D2025ff",
        yBlue: "#3956A5", 
        hanBlue: "#5169CC",
        hanBlue2: "#303133",
        cornflowerBlue: "#7E91D0",
        maxBluePurple: "#99AFEB",
        aliceBlue: "#E9EFFC",
        gray: "#A5A5A5",
        hanGray: "#eef2f4",
        white: "#F8F8F8"
    }
}
