import React from "react";

import './about.css';

export class About extends React.Component {
    render () {
        const {
            backgroundColor,
            description,
            aboutTextColor,
            call
        } = this.props;
        return (
            <div className="about"
                 style={{backgroundColor: `${backgroundColor}`,}}>
                <div className="h2 ml-3"
                     style={{color: `${aboutTextColor}`}}>
                    About me
                </div>
                <div className="text-sm-left">
                    {description}
                </div>
                <div className="text-center">
                    {call}
                </div>
            </div>
        );
    }
}