import React from 'react';

import './wrapper.css';
import {palette} from '../styles';

import { Header } from './header';
import { About } from './about';
import { Menu } from './menu';
import { SocialMenu } from './social-menu';

const fonts = (
    <style>
        @import url('https://fonts.googleapis.com/css2?family=Cutive&family=Montserrat:ital,wght@0,700;1,400&family=Open+Sans:ital,wght@0,400;0,800;1,400&family=Oranienbaum&family=Slabo+13px&family=Special+Elite&display=swap');
    </style> 
)

// font-family: 'Cutive', serif;

// font-family: 'Montserrat', sans-serif;

// font-family: 'Open Sans', sans-serif;

// font-family: 'Oranienbaum', serif;

// font-family: 'Slabo 13px', serif;

// font-family: 'Special Elite', cursive;

export class Wrapper extends React.Component {
    render () {
        return (
            <div className="wrapper d-flex justify-content-center"
                 style={wrapperColors}>
                 {fonts}
                <Header 
                    logoSrc={require('./logoJC.png')}
                    title="João Claudio"
                    titleFont={{
                        fontFamily: "'Montserrat', sans-serif"
                    }}
                    description="Physics, professor, data scientist"
                    descriptionFont={{
                        fontFamily: "'Montserrat', sans-serif",
                        fontStyle: "italic"
                    }}
                    profilePictureSrc={require("./jc3.png")}
                    overlayColor={wrapperColors.backgroundColor}
                />
                <About
                    backgroundColor={wrapperColors.backgroundColor}
                    description={aboutmeDescription}
                    aboutTextColor={palette.bluey.hanBlue2}
                    call={call}
                />
                <Menu menuColor={palette.bluey.white}>
                    <SocialMenu buttonColor={palette.bluey.hanBlue2}
                                width={"90%"}
                                height={"100%"}
                                buttonSize="5.5rem"
                    >

                    </SocialMenu>
                    {/* <BlankButton
                        href="https://github.com/bessavagner"
                        buttonColor={palette.bluey.hanBlue2}
                    >
                        {githubIcon}
                    </BlankButton> */}
                </Menu>
            </div>
        );
    }
};


const wrapperColors = {
    backgroundColor: palette.bluey.hanGray,
    color: palette.bluey.gray
}

const aboutmeDescription =  (
    <div className="m-1">
        <p className="m-4" style={{fontSize: "1.5rem"}}>
        With the user of high level problem-solving skill completed 
        successfully 200+ projects I participated or led. 
        </p>
        <p className="m-2 h3">Skills</p>
        <p className="m-3" style={{fontSize: "1.5rem"}}>Statistics & Analytics, Machine Learning, Data cleansing, 
            modeling and visualization applied to businness decision.</p>
    </div>    
)

const call = (
    <p style={{fontSize: "2rem"}}>Let's work together?</p>
)
