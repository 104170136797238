import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";

import './buttons.css'

export const BlankButton = ({children, href, buttonColor, fontSize="6rem"}) => {
    return (
        <Router>
            <a href={href} target="_blank" rel="noreferrer" className="m-4">
                <button className="blank" style={{color: `${buttonColor}`, fontSize: `${fontSize}`}}>
                    {children}
                </button>               
            </a>            
        </Router>
    )
}